import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import flower from "../img/flower.svg";
import CeremonyRoll from "../components/CeremonyRoll";
import ContactForm from "../components/ContactForm";
import scrollTo from "../scrollToContact";

export const CeremonyPageTemplate = ({
  content,
  contentComponent,
  description,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <>
      <Header>
        <Link to="/">Back</Link>
        <img className="right" src={flower} />
        <div className="inner">
          <h1>{title}</h1>
          <p>{description}</p>
          <button onClick={scrollTo}>Get in touch</button>
        </div>
      </Header>
      <Section>
        {helmet || ""}
        <div className="inner">
          <PostContent content={content} />
          <button onClick={scrollTo}>Get in touch</button>
        </div>
      </Section>
      <Section>
        <CeremonyRoll />
      </Section>
      <Section>
        <img className="left" src={flower} />
        <ContactForm />
      </Section>
    </>
  );
};

const Header = styled.header`
  padding: 120px 0;
  background: var(--bg);
  position: relative;

  a {
    position: absolute;
    top: 20px;
    left: 20px;

    &:hover {
    }
  }

  button {
    background-color: #05784e;
    color: var(--light);
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    border: none;
    cursor: pointer;
    box-shadow: inset 0 -0.2rem rgba(0, 0, 0, 0.5),
      inset 0 0.2rem rgba(255, 255, 255, 0.2);
  }

  .left {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(180deg);
    width: 25%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }

  .right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }

  h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
`;

const Section = styled.div`
  width: 100vw;
  background-color: var(--light);
  padding-bottom: 60px;
  position: relative;

  button {
    background-color: #05784e;
    color: var(--white);
    border-radius: 5px;
    padding: 20px 40px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 20px;
    border: none;
    cursor: pointer;
    box-shadow: inset 0 -0.2rem rgba(0, 0, 0, 0.5),
      inset 0 0.2rem rgba(255, 255, 255, 0.2);
  }

  &:first-of-type {
    padding-top: 60px;
  }

  .left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    opacity: 50%;

    @media (max-width: 760px) {
      width: 50%;
    }
  }
`;

CeremonyPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const Ceremony = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <CeremonyPageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Ceremony">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

Ceremony.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Ceremony;

export const pageQuery = graphql`
  query CeremonyPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
