import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const HTMLContent = ({ content, className }) => (
  <Div className={className} dangerouslySetInnerHTML={{ __html: content }} />
);

const Content = ({ content, className }) => (
  <Div className={className}>{content}</Div>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

const Div = styled.div`
  img {
    max-width: 100%;
    border-radius: 10px;
  }

  p {
    margin-bottom: 20px;
  }
`;

HTMLContent.propTypes = Content.propTypes;

export default Content;
